<template>
  <div id="page-sponsors">
    <!-- sponsors Editor Modal -->
    <MemberProfileSponsorsEditorModal 
      ref="edit_sponsors"
      :sponsor_id="selected_sponsors_id"
      @created="created_sponsors"
      @updated="updated_sponsors"
      @cancelled="cancelled_sponsors"
    />

    <!-- sponsors Table -->
    <MemberProfileSponsorsTable 
      ref="sponsors_table"
      @on_select_item="on_select_item"
      @on_delete_item="on_delete_item"
      @create_clicked="create_clicked"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import MemberProfileSponsorsTable from '@/view/pages/ml/member_profile/MemberProfileSponsorsTable.vue';
import MemberProfileSponsorsEditorModal from '@/view/pages/ml/member_profile/MemberProfileSponsorsEditorModal.vue';

export default {
  name: 'MemberProfilesponsors',
  components: {
    MemberProfileSponsorsTable,
    MemberProfileSponsorsEditorModal
  },
  computed: {
    ...mapGetters(['is_ths', 'currentCompanyId', 'current_user'])
  },
  mixins: [toasts],
  data() {
    return {
      selected_sponsors_id: null,
      items: []
    };
  },
  methods: {
    created_sponsors(sponsors) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('SPONSOR.CREATED'));
      this.$refs.sponsors_table.refresh();
    },
    updated_sponsors(sponsors, notify) {
      this.$refs.sponsors_table.refresh();
      if (notify) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('SPONSOR.UPDATED'));
      }
    },
    cancelled_sponsors() {
      this.selected_sponsors_id = null;
      this.$refs.sponsors_table.refresh();
    },
    create_clicked() {
      this.selected_sponsors_id = null;
      this.$nextTick(() => {
        this.$refs.edit_sponsors.show();
      });
    },
    on_select_item(id) {
      this.selected_sponsors_id = id;
      this.$nextTick(() => {
        this.$refs.edit_sponsors.show();
      });
    },
    async on_delete_item(id) {
      try {
        const res = await axios.delete(`/sponsor/${id}`);
        if (res.status === 200) {
          this.$refs.sponsors_table.refresh();
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SPONSOR.DELETED'));
        }
      } catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SPONSOR.UNABLE_DELETE'));
      }
    }
  }
};
</script>
